let apiUrl, cardUrl, photoUrl
const expressPort = 3003
const apiUrls = {
  production: 'https://api.ivcard.link/api/v1',
  development: `http://localhost:${expressPort}/api/v1`,
  cardUrlDev: `http://localhost:${expressPort}/card`,
  cardUrlProd: 'https://api.ivcard.link/card',
  photoUrlDev: `http://localhost:${expressPort}/image`,
  photoUrl: 'https://api.ivcard.link/image'
  
}

if (window.location.hostname === 'localhost' || window.location.hostname.includes('192')) {
  apiUrl = apiUrls.development
  cardUrl = apiUrls.cardUrlDev
  photoUrl = apiUrls.photoUrlDev
} else {
  apiUrl = apiUrls.production
  cardUrl = apiUrls.cardUrlProd
  photoUrl = apiUrls.photoUrl

}

export {cardUrl, photoUrl};
export default  apiUrl
import React, { Component } from "react";

const DataContext = React.createContext();
class DataProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLoggedIn: false,
      locale: "ar",
      rtl: true,
      collapsed: false,
      toggled: false,
      user: null,
        test: "123"
    };
  }

  handleRtlChange = () => {
    if (this.state.rtl) {
      this.setState({ rtl: false, locale: "en" });
    } else {
      this.setState({ rtl: true, locale: "ar" });
    }
  };

  async componentDidMount() {
    try {
     
    } catch (error) {
      
    }
  }

  setUser = user => this.setState({user})

  
  render() {
    return (
      <DataContext.Provider
        value={{
          ...this.state,
          handleRtlChange: this.handleRtlChange,
          setUser: this.setUser,
          setSelectedCategory: this.setSelectedCategory,
          addToCart: this.addToCart,
          setCartQuantity: this.setCartQuantity,
          setSelectedProduct: this.setSelectedProduct,
          changQuantity: this.changQuantity,
        }}
      >
        {this.props.children}
      </DataContext.Provider>
    );
  }
}
const DataConsumer = DataContext.Consumer;

export { DataProvider, DataConsumer, DataContext };

import axios from 'axios';

import apiUrl from './config'

export const getVCard = (slug) => {
    return axios.get(`${apiUrl}/info/${slug}`)
}
export const postSignup = (data) => {
    return axios.post(`${apiUrl}/sign-up`, data)
}
export const postLogin = (data) => {
    return axios.post(`${apiUrl}/sign-in`, data)
}
export const patchUser = (data, token) => {
    return axios.patch(`${apiUrl}/users`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

export const resetPassword = (data, token) => {
    return axios.patch(`${apiUrl}/reset-password`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}
